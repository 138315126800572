import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import mobileNavigationDe from './de/mobileNavigation.json';
import mobileNavigationEn from './en/mobileNavigation.json';
import headerDe from './de/header.json';
import headerEn from './en/header.json';
import footerDe from './de/footer.json';
import footerEn from './en/footer.json';
import availabilityDe from './de/availability.json';
import availabilityEn from './en/availability.json';
import mainDe from './de/main.json';
import mainEn from './en/main.json';
import imprintDe from './de/imprint.json';
import imprintEn from './en/imprint.json';
import aboutUsDe from './de/aboutUs.json';
import aboutUsEn from './en/aboutUs.json';
import informationDe from './de/information.json';
import informationEn from './en/information.json';
import informationBarDe from './de/informationBar.json';
import informationBarEn from './en/informationBar.json';
import menuDe from './de/menu.json';
import menuEn from './en/menu.json';
import contactDe from './de/contact.json';
import contactEn from './en/contact.json';

export const resources = {
  de: {
    mobileNavigation: mobileNavigationDe,
    header: headerDe,
    footer: footerDe,
    availability: availabilityDe,
    main: mainDe,
    imprint: imprintDe,
    aboutUs: aboutUsDe,
    information: informationDe,
    informationBar: informationBarDe,
    menu: menuDe,
    contact: contactDe,
  },
  en: {
    mobileNavigation: mobileNavigationEn,
    header: headerEn,
    footer: footerEn,
    availability: availabilityEn,
    main: mainEn,
    imprint: imprintEn,
    aboutUs: aboutUsEn,
    information: informationEn,
    informationBar: informationBarEn,
    menu: menuEn,
    contact: contactEn,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: true,
    lng: 'de',
    fallbackLng: 'de',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
